<template>
  <div class="main-layout">
    <Header />
    <Menu class="d-none d-lg-block" />
    <notifications></notifications>
    <router-view class="wrap" />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Menu from '@/components/Menu';
import Header from '@/components/Header';
// import Footer from '@/components/Footer';

export default {
  name: 'main-loyout',
  components: {
    Menu,
    // Footer,
    Header,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.loading = false;
    this.$store.dispatch('getProfile');
    this.$store.dispatch('getPagination');
  },
};
</script>

<style>
.main-layout .wrap {
  margin: 64px 0 40px 56px;
}
@media screen and (max-width: 1263px) {
  .main-layout .wrap {
    margin: 64px 0 40px 0;
  }
}
</style>
