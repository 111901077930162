import '@/plugins/axios';

const api = '/admin/api/v1/spam-filters/';

export default {
  state: {
    spam: {},
  },
  actions: {
    getSpam(context) {
      return axios.get(api).then((res) => {
        context.commit('GET_SPAM', res.data);
      });
    },
    setSpam: (context, payload) => {
      context.commit('GET_SPAM', payload);
    },
    createSpam(context, payload) {
      return axios.post(api, payload);
    },
    updateSpam(context, { id, data }) {
      return axios.patch(api + id, data);
    },
    deleteSpam(context, payload) {
      return axios.delete(api + payload).then(() => {
        context.dispatch('getSpam');
      });
    },
  },
  mutations: {
    GET_SPAM: (state, payload) => {
      state.spam = payload;
      return state.spam;
    },
  },
  getters: {
    spam(state) {
      return state.spam;
    },
  },
};
