<template>
  <v-list flat>
    <v-list-item-group color="primary">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="`/${item.link}`"
        class="font-weight-medium"
        active-class="active"
        :class="role.name == 'admin' ? '' : 'd-none'"
      >
        <v-list-item-icon class="my-3 mr-5">
          <v-img :src="getImage(item.icon)" class="icon"></v-img>
        </v-list-item-icon>
        <v-list-item-content class="py-2">
          <v-list-item-title class="f18 white--text">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'Dashboard', link: 'dashboard', icon: 'dash' },
        { title: 'Staff', link: 'staff', icon: 'staff' },
        { title: 'Prices', link: 'prices', icon: 'prices' },
        { title: 'Accounts', link: 'accounts', icon: 'accounts' },
        { title: 'Users', link: 'users', icon: 'users' },
        { title: 'Statements', link: 'statements', icon: 'statements' },
        { title: 'Campaigns', link: 'campaigns', icon: 'campaign' },
        { title: 'Messages', link: 'messages', icon: 'msg' },
        { title: 'Statistic', link: 'statistic', icon: 'statistic' },
        { title: 'Spam filter', link: 'spam', icon: 'spam' },
        { title: 'Providers', link: 'providers', icon: 'providers' },
        { title: 'Tickets', link: 'tickets', icon: 'tickets' },
        { title: 'Refbook', link: 'refbook', icon: 'refbook' },
        { title: 'Setting', link: 'setting', icon: 'setting' },
      ],
    };
  },
  methods: {
    getImage(imgPathSegment) {
      return require(`@/assets/icon/${imgPathSegment}.svg`);
    },
  },
  computed: {
    role() {
      return this.$store.getters.profile.role;
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  border-left: 8px solid;
  padding: 0 16px 0 8px !important;

  .icon {
    filter: brightness(119%);
  }
}
</style>
