import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/';

export default {
  actions: {
    logout(context) {
      return axios.post(api + 'sign-out').then(() => {
        sessionStorage.clear();
        router.push('/login');
      });
    },
    signIn(context, { login, pass }) {
      return axios.post(api + 'sign-in/email', {
        email: login,
        password: pass,
      });
    },
    signUp(context, payload) {
      return axios.post(api + 'sign-up/email', payload);
    },
    confirm(context, payload) {
      return axios.post(api + 'confirm-email', payload);
    },
    reqResPass(context, payload) {
      return axios.post(api + 'request-password-reset', payload);
    },
    resPass(context, payload) {
      return axios.post(api + 'password-reset', payload);
    },
    otp(context, payload) {
      return axios.post(api + 'sign-in/otp', {
        code: payload,
      });
    },
    otpRecovery(context, payload) {
      return axios.post(api + 'sign-in/otp-recovery', {
        code: payload,
      });
    },
    refresh(context) {
      return axios
        .post(api + 'refresh')
        .then((response) => {
          sessionStorage.setItem('jwt_token', response.data.access_token);
        })
        .catch(() => {
          sessionStorage.clear();
          const loginpath = window.location.pathname;
          router.replace(loginpath);
        });
    },
  },
};
