import '@/plugins/axios';

const api = '/admin/api/v1/settings';

export default {
  state: {
    setting: {},
  },
  actions: {
    getSetting(context) {
      return axios.get(api).then((res) => {
        context.commit('GET_SETTING', res.data);
      });
    },
    updateSetting(context, payload) {
      return axios.patch(api, payload).then((res) => {
        context.commit('GET_SETTING', res.data);
      });
    },
    setSetting: (context, payload) => {
      context.commit('GET_SETTING', payload);
    },
  },
  mutations: {
    GET_SETTING: (state, payload) => {
      state.setting = payload;
      return state.setting;
    },
  },
  getters: {
    setting(state) {
      return state.setting;
    },
  },
};
