import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/accounts/';

export default {
  state: {
    account: {},
    accountsAll: [],
    accountAddress: {},
    members: [],
    membersUser: { user: {} },
    billing: {
      billing_period: {},
      currency: {},
      payment_mode: {},
    },
    transactionsList: {},
    accountsRest: { sandbox: {}, live: {} },
    accountsSmpp: { sandbox: {}, live: {} },
    accountsList: {},
  },
  actions: {
    getAccountsList(context, { pageSize, search, page, status }) {
      if (!!search || page > 1 || pageSize > 10 || status != 'all') {
        if (
          `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${search ? `src=${search}&` : ''}page=${
            page || 1
          }&page_size=${pageSize}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${search ? `src=${search}&` : ''}page=${
              page || 1
            }&page_size=${pageSize}`
          );
        }
        return axios
          .get(
            `${api}${status != 'all' ? `?status=${status}&` : '?'}${search ? `src=${search}&` : ''}page=${
              page - 1 || 0
            }&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_ACCOUNTSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_ACCOUNTSLIST', res.data);
        });
      }
    },
    setAccountsList: (context, payload) => {
      context.commit('GET_ACCOUNTSLIST', payload);
    },
    getAccountsAll(context) {
      return axios.get(api + 'all').then((res) => {
        context.commit('GET_ACCOUNTALL', res.data);
      });
    },
    setAccountsAll: (context, payload) => {
      context.commit('GET_ACCOUNTALL', payload);
    },
    getAccount(context) {
      return axios.get(api + router.app._route.params.accountId).then((res) => {
        context.commit('GET_ACCOUNT', res.data);
      });
    },
    setAccount: (context, payload) => {
      context.commit('GET_ACCOUNT', payload);
    },
    updateAccount(context, payload) {
      return axios.put(api + router.app._route.params.accountId, payload).then((res) => {
        context.commit('GET_ACCOUNT', res.data);
      });
    },
    blockAccount(context) {
      return axios.post(api + router.app._route.params.accountId + '/block').then((res) => {
        context.commit('GET_ACCOUNT', res.data);
      });
    },
    activeAccount(context) {
      return axios.post(api + router.app._route.params.accountId + '/activate').then((res) => {
        context.commit('GET_ACCOUNT', res.data);
      });
    },
    //                               ADDRESS
    getAccountAddress(context) {
      return axios.get(api + router.app._route.params.accountId + '/address').then((res) => {
        context.commit('GET_ACCOUNTADDRESS', res.data);
      });
    },
    setAccountAddress: (context, payload) => {
      context.commit('GET_ACCOUNTADDRESS', payload);
    },
    //                               MEMBERS
    getAccountMembers(context) {
      return axios.get(api + router.app._route.params.accountId + '/members/').then((res) => {
        context.commit('GET_ACCOUNTMEMBERS', res.data);
      });
    },
    setAccountMembers: (context, payload) => {
      context.commit('GET_ACCOUNTMEMBERS', payload);
    },
    //                               MEMBERS USER
    getAccountMembersUser(context) {
      return axios.get(api + router.app._route.params.accountId + '/members/' + router.app._route.params.accountMembersId).then((res) => {
        context.commit('GET_ACCOUNTMEMBERSUSER', res.data);
      });
    },
    setAccountMembersUser: (context, payload) => {
      context.commit('GET_ACCOUNTMEMBERSUSER', payload);
    },
    updateAccountMembers(context, payload) {
      return axios
        .put(api + router.app._route.params.accountId + '/members/' + router.app._route.params.accountMembersId, payload)
        .then((res) => {
          context.commit('GET_ACCOUNTMEMBERSUSER', res.data);
        });
    },
    deleteAccountMembers(context) {
      return axios.delete(api + router.app._route.params.accountId + '/members/' + router.app._route.params.accountMembersId);
    },
    //                               BILLING
    getAccountBilling(context) {
      return axios.get(api + router.app._route.params.accountId + '/billing').then((res) => {
        context.commit('GET_ACCOUNTBILLING', res.data);
      });
    },
    setAccountBilling: (context, payload) => {
      context.commit('GET_ACCOUNTBILLING', payload);
    },
    updateAccountBilling(context, payload) {
      return axios.put(api + router.app._route.params.accountId + '/billing', payload).then((res) => {
        context.commit('GET_ACCOUNTBILLING', res.data);
      });
    },
    //                               TRANSACTIONS
    getAccountTransactions(context, { pageSize, search, page }) {
      if (!!search || pageSize > 10) {
        return axios
          .get(
            `${api}${router.app._route.params.accountId}/payments/${search ? `?src=${search}&` : '?'}page=${
              page - 1 || 0
            }&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_ACCOUNTTRANSACTIONS', res.data);
          });
      } else {
        return axios.get(`${api}${router.app._route.params.accountId}/payments/?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_ACCOUNTTRANSACTIONS', res.data);
        });
      }
    },
    setAccountTransactions: (context, payload) => {
      context.commit('GET_ACCOUNTTRANSACTIONS', payload);
    },
    updateAccountTransactions(context, payload) {
      return axios.post(api + router.app._route.params.accountId + '/payments/', payload);
    },
    //                               REST
    getAccountRest(context) {
      return axios.get(api + router.app._route.params.accountId + '/external-api/rest').then((res) => {
        context.commit('GET_ACCOUNTREST', res.data);
      });
    },
    setAccountRest: (context, payload) => {
      context.commit('GET_ACCOUNTREST', payload);
    },
    updateAccountRest(context, payload) {
      return axios.patch(api + router.app._route.params.accountId + '/external-api/rest', payload).then((res) => {
        context.commit('GET_ACCOUNTREST', res.data);
      });
    },
    //                               SMPP
    getAccountSmpp(context) {
      return axios.get(api + router.app._route.params.accountId + '/external-api/smpp').then((res) => {
        context.commit('GET_ACCOUNTSMPP', res.data);
      });
    },
    setAccountSmpp: (context, payload) => {
      context.commit('GET_ACCOUNTSMPP', payload);
    },
    updateAccountSmpp(context, payload) {
      return axios.patch(api + router.app._route.params.accountId + '/external-api/smpp', payload).then((res) => {
        context.commit('GET_ACCOUNTSMPP', res.data);
      });
    },
  },
  mutations: {
    GET_ACCOUNTALL: (state, payload) => {
      state.accountsAll = payload;
      return state.accountsAll;
    },
    GET_ACCOUNT: (state, payload) => {
      state.account = payload;
      return state.account;
    },
    GET_ACCOUNTADDRESS: (state, payload) => {
      state.accountAddress = payload;
      return state.accountAddress;
    },
    GET_ACCOUNTMEMBERS: (state, payload) => {
      state.members = payload;
      return state.members;
    },
    GET_ACCOUNTMEMBERSUSER: (state, payload) => {
      state.membersUser = payload;
      return state.membersUser;
    },
    GET_ACCOUNTBILLING: (state, payload) => {
      state.billing = payload;
      return state.billing;
    },
    GET_ACCOUNTTRANSACTIONS: (state, payload) => {
      state.transactionsList = payload;
      return state.transactionsList;
    },
    GET_ACCOUNTREST: (state, payload) => {
      state.accountsRest = payload;
      return state.accountsRest;
    },
    GET_ACCOUNTSMPP: (state, payload) => {
      state.accountsSmpp = payload;
      return state.accountsSmpp;
    },
    GET_ACCOUNTSLIST: (state, payload) => {
      state.accountsList = payload;
      return state.accountsList;
    },
  },
  getters: {
    accountsAll(state) {
      return state.accountsAll;
    },
    account(state) {
      return state.account;
    },
    accountAddress(state) {
      return state.accountAddress;
    },
    members(state) {
      return state.members;
    },
    membersUser(state) {
      return state.membersUser;
    },
    billing(state) {
      return state.billing;
    },
    transactionsList(state) {
      return state.transactionsList;
    },
    accountsRest(state) {
      return state.accountsRest;
    },
    accountsSmpp(state) {
      return state.accountsSmpp;
    },
    accountsList(state) {
      return state.accountsList;
    },
  },
};
