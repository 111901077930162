import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/users/';

export default {
  state: {
    usersList: {},
    user: { status: {} },
  },
  actions: {
    getUsersList(context, { pageSize, search, page }) {
      if (!!search || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}`);
        }
        return axios.get(`${api}${search ? `?src=${search}&` : '?'}page=${page - 1 || 0}&page_size=${pageSize}`).then((res) => {
          context.commit('GET_USERSLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_USERSLIST', res.data);
        });
      }
    },
    setUsersList: (context, payload) => {
      context.commit('GET_USERSLIST', payload);
    },
    getUser(context) {
      return axios.get(api + router.app._route.params.userId).then((res) => {
        context.commit('GET_USER', res.data);
      });
    },
    setUser: (context, payload) => {
      context.commit('GET_USER', payload);
    },
    getUserActiv(context) {
      return axios.get(api + router.app._route.params.userId + '/activate').then((res) => {
        context.commit('GET_USER', res.data);
      });
    },
    getUserDeactiv(context) {
      return axios.get(api + router.app._route.params.userId + '/deactivate').then((res) => {
        context.commit('GET_USER', res.data);
      });
    },
  },
  mutations: {
    GET_USERSLIST: (state, payload) => {
      state.usersList = payload;
      return state.usersList;
    },
    GET_USER: (state, payload) => {
      state.user = payload;
      return state.user;
    },
  },
  getters: {
    usersList(state) {
      return state.usersList;
    },
    user(state) {
      return state.user;
    },
  },
};
