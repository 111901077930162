import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import notifications from './modules/notifications';
import auth from './modules/auth';
import profile from './modules/profile';
import staff from './modules/staff';
import prices from './modules/prices';
import accounts from './modules/accounts';
import providers from './modules/providers';
import tickets from './modules/tickets';
import users from './modules/users';
import spam from './modules/spam';
import statements from './modules/statements';
import campaign from './modules/campaign';
import setting from './modules/setting';
import refbook from './modules/refbook';
import messages from './modules/messages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    notifications,
    auth,
    profile,
    staff,
    prices,
    accounts,
    providers,
    tickets,
    users,
    spam,
    statements,
    campaign,
    setting,
    refbook,
    messages,
  },
});
