import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/staff/';

export default {
  state: {
    staffList: {},
    staffAll: [],
    staff: { role: {} },
  },
  actions: {
    getStaffList(context, { pageSize, search, page }) {
      if (!!search || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}`);
        }
        return axios.get(`${api}${!!search ? `?src=${search}&` : '?'}page=${page - 1 || 0}&page_size=${pageSize}`).then((res) => {
          context.commit('GET_STAFFLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_STAFFLIST', res.data);
        });
      }
    },
    setStaffList: (context, payload) => {
      context.commit('GET_STAFFLIST', payload);
    },
    createStaff(context, payload) {
      return axios({
        method: 'post',
        url: api,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getStaff(context) {
      return axios.get(api + router.app._route.params.staffId).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    setStaff: (context, payload) => {
      context.commit('GET_STAFF', payload);
    },
    setPhotoStaff(context, payload) {
      return axios.post(api + router.app._route.params.staffId + '/photo', payload).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    deletePhotoStaff(context) {
      return axios.delete(api + router.app._route.params.staffId + '/photo');
    },
    updateStaff(context, payload) {
      return axios.patch(api + router.app._route.params.staffId, payload);
    },
    sendEmailStaff(context) {
      return axios.post(api + router.app._route.params.staffId + '/send-email-confirmation').then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    resetPassStaff(context) {
      return axios.post(api + router.app._route.params.staffId + '/reset-password').then(() => {
        context.dispatch('getStaff');
      });
    },
    activateStaff(context) {
      return axios.post(api + router.app._route.params.staffId + '/activate').then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    deactivateStaff(context) {
      return axios.post(api + router.app._route.params.staffId + '/deactivate').then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    getStaffAll(context, payload) {
      return axios.get(`${api}all?role=${payload || ''}`).then((res) => {
        context.commit('GET_STAFFALL', res.data);
      });
    },
    setStaffAll: (context, payload) => {
      context.commit('GET_STAFFALL', payload);
    },
  },
  mutations: {
    GET_STAFFLIST: (state, payload) => {
      state.staffList = payload;
      return state.staffList;
    },
    GET_STAFFALL: (state, payload) => {
      state.staffAll = payload;
      return state.staffAll;
    },
    GET_STAFF: (state, payload) => {
      state.staff = payload;
      return state.staff;
    },
  },
  getters: {
    staffList(state) {
      return state.staffList;
    },
    staffAll(state) {
      return state.staffAll;
    },
    staff(state) {
      return state.staff;
    },
  },
};
