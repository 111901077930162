import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/providers/';

export default {
  state: {
    providersList: {},
    providerAll: [],
    provider: { limits: {} },
    providerBinds: { binds: [] },
    providerLogs: { result: [] },
  },
  actions: {
    getProvidersList(context, { pageSize, search, page }) {
      if (!!search || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}`);
        }
        return axios.get(`${api}${search ? `?src=${search}&` : '?'}page=${page - 1 || 0}&page_size=${pageSize}`).then((res) => {
          context.commit('GET_PROVIDERSLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_PROVIDERSLIST', res.data);
        });
      }
    },
    setProvidersList: (context, payload) => {
      context.commit('GET_PROVIDERSLIST', payload);
    },
    createProvider: (context, payload) => {
      return axios.post(api, payload);
    },
    getProvider: (context) => {
      return axios.get(api + router.app._route.params.providerId).then((res) => {
        context.commit('GET_PROVIDER', res.data);
      });
    },
    setProvider: (context, payload) => {
      context.commit('GET_PROVIDER', payload);
    },
    updateProvider(context, payload) {
      return axios.patch(api + router.app._route.params.providerId, payload);
    },
    deleteProvider(context) {
      return axios.delete(api + router.app._route.params.providerId);
    },
    getProviderBinds: (context) => {
      return axios.get(api + router.app._route.params.providerId + '/binds/').then((res) => {
        context.commit('GET_PROVIDERBINDS', res.data);
      });
    },
    setProviderBinds: (context, payload) => {
      context.commit('GET_PROVIDERBINDS', payload);
    },
    getProviderLogs: (context, payload) => {
      return axios.get(`${api}${router.app._route.params.providerId}/logs/?log_level=${payload}`).then((res) => {
        context.commit('GET_PROVIDERLOGS', res.data);
      });
    },
    setProviderLogs: (context, payload) => {
      context.commit('GET_PROVIDERLOGS', payload);
    },
    getProviderAll(context) {
      return axios.get(api + 'all').then((res) => {
        context.commit('GET_PROVIDERALL', res.data);
      });
    },
    setProviderAll: (context, payload) => {
      context.commit('GET_PROVIDERALL', payload);
    },
  },
  mutations: {
    GET_PROVIDERSLIST: (state, payload) => {
      state.providersList = payload;
      return state.providersList;
    },
    GET_PROVIDER: (state, payload) => {
      state.provider = payload;
      return state.provider;
    },
    GET_PROVIDERBINDS: (state, payload) => {
      state.providerBinds = payload;
      return state.providerBinds;
    },
    GET_PROVIDERLOGS: (state, payload) => {
      state.providerLogs = payload;
      return state.providerLogs;
    },
    GET_PROVIDERALL: (state, payload) => {
      state.providerAll = payload;
      return state.providerAll;
    },
  },
  getters: {
    providersList(state) {
      return state.providersList;
    },
    provider(state) {
      return state.provider;
    },
    providerBinds(state) {
      return state.providerBinds;
    },
    providerLogs(state) {
      return state.providerLogs;
    },
    providerAll(state) {
      return state.providerAll;
    },
  },
};
