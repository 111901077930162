import '@/plugins/axios';

const api = '/admin/api/v1/profile';
const api2fa = '/admin/api/v1/profile/2fa/otp/';

export default {
  state: {
    profile: { role: {} },
    codes: [],
    qr: {},
  },
  actions: {
    getProfile(context) {
      return axios.get(api).then((res) => {
        context.commit('GET_PROFILE', res.data);
      });
    },
    updateProfile(context, payload) {
      return axios.put(api, payload).then((res) => {
        context.commit('GET_PROFILE', res.data);
      });
    },
    setPhoto(context, payload) {
      return axios.post(api + '/photo', payload).then((res) => {
        context.commit('GET_PROFILE', res.data);
      });
    },
    deletePhoto(context) {
      return axios.delete(api + '/photo');
    },
    newPassword(context, payload) {
      return axios.post(api + '/password', payload);
    },
    sendEmail(context) {
      return axios.post(api + '/send-email-confirmation').then((res) => {
        context.commit('GET_PROFILE', res.data);
      });
    },
    getCodes(context) {
      return axios.get(api2fa + 'recovery-codes').then((res) => {
        context.commit('GET_CODES', res.data);
      });
    },
    generate(context) {
      return axios.post(api2fa + 'generate-recovery-codes').then((res) => {
        context.commit('GET_CODES', res.data);
      });
    },
    step2(context) {
      return axios.get(api2fa + 'prepare').then((res) => {
        context.commit('GET_QR', res.data);
      });
    },
    enable(context, payload) {
      return axios
        .post(api2fa + 'enable', {
          code: payload,
        })
        .then(() => {
          context.dispatch('getProfile');
        });
    },
    disable(context) {
      return axios.post(api2fa + 'disable').then((res) => {
        context.dispatch('getProfile');
      });
    },
  },
  mutations: {
    GET_PROFILE: (state, payload) => {
      state.profile = payload;
      return state.profile;
    },
    GET_CODES: (state, payload) => {
      state.codes = payload;
      return state.codes;
    },
    GET_QR: (state, payload) => {
      state.qr = payload;
      return state.qr;
    },
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    codes(state) {
      return state.codes;
    },
    qr(state) {
      return state.qr;
    },
  },
};
