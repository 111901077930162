import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Dashboard.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Profile.vue'),
    },
    {
      path: '/staff',
      name: 'Platform Staff',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/StaffList.vue'),
    },
    {
      path: '/staff-new',
      name: 'New staff',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/StaffNew.vue'),
    },
    {
      path: '/staff/:staffId',
      name: 'Staff profile',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Staff.vue'),
    },
    {
      path: '/prices',
      name: 'Prices',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Prices.vue'),
    },
    {
      path: '/accounts',
      name: 'Accounts',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/AccountsList.vue'),
    },
    {
      path: '/accounts/:accountId',
      name: 'Account',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Account.vue'),
    },
    {
      path: '/accounts/:accountId/members/:accountMembersId',
      name: 'Account members',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/AccountMembers.vue'),
    },
    {
      path: '/users',
      name: 'Users',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/UsersList.vue'),
    },
    {
      path: '/users/:userId',
      name: 'User',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/User.vue'),
    },
    {
      path: '/statements',
      name: 'Statements',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/StatementsList.vue'),
    },
    {
      path: '/campaigns',
      name: 'Campaigns',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/CampaignList.vue'),
    },
    {
      path: '/campaigns/:campaignId',
      name: 'Campaign',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Campaign.vue'),
    },
    {
      path: '/campaigns/:campaignId/details',
      name: 'Campaign details',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/CampaignDetails.vue'),
    },
    {
      path: '/messages',
      name: 'Messages',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/MessagesList.vue'),
    },
    {
      path: '/messages/:msgId',
      name: 'Message',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Messages.vue'),
    },
    {
      path: '/statistic',
      name: 'Statistic',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/StatisticsList.vue'),
    },
    {
      path: '/providers',
      name: 'Providers',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/ProvidersList.vue'),
    },
    {
      path: '/spam',
      name: 'Spam filter',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Spam.vue'),
    },
    {
      path: '/provider-new',
      name: 'New provider',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/ProviderNew.vue'),
    },
    {
      path: '/providers/:providerId',
      name: 'Provider',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Provider.vue'),
    },
    {
      path: '/refbook',
      name: 'RefBook',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Refbook.vue'),
    },
    {
      path: '/tickets',
      name: 'Tickets',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/TicketsList.vue'),
    },
    {
      path: '/tickets/:ticketId',
      name: 'Ticket',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Ticket.vue'),
    },
    {
      path: '/setting',
      name: 'Setting',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Setting.vue'),
    },
    {
      path: '/notifications',
      name: 'Notifications',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Notifications.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/email-confirmation',
      name: 'Confirmation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Confirmation.vue'),
    },
    {
      path: '/already-confirmed',
      name: 'Already',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Already.vue'),
    },
    {
      path: '/failed-confirmed',
      name: 'Failed',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Failed.vue'),
    },
    {
      path: '/expired',
      name: 'Expired',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Expired.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Dashboard' },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = sessionStorage.getItem('jwt_token');
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth && !currentUser) {
    next({ name: 'Login', query: { from: to.fullPath } });
  } else {
    next();
  }
});

export default router;
