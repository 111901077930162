import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/tickets/';
const url = window.location.host;
const token = sessionStorage.getItem('jwt_token');
let socket = null;
let timerID = null;

export default {
  state: {
    ticketsList: { topic: {}, staff: {}, status: {}, account: {} },
    ticket: { topic: {}, staff: {}, status: {}, account: {} },
    ticketAccount: { payment_mode: {}, status: {}, moderation: {}, billing_period: {} },
    ticketAll: [],
    ticketAllTwo: [],
    ticketMsg: [],
  },
  actions: {
    getTicketsList(context, { pageSize, search, page, status, topic }) {
      if (!!search || topic != 'all' || status != 'all' || pageSize > 10 || page > 1) {
        if (
          `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${topic != 'all' ? `topic=${topic}&` : ''}${
            !!search ? `src=${search}&` : ''
          }page=${page}&page_size=${pageSize}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${topic != 'all' ? `topic=${topic}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page}&page_size=${pageSize}`
          );
        }
        return axios
          .get(
            `${api}${status != 'all' ? `?status=${status}&` : '?'}${topic != 'all' ? `topic=${topic}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page - 1 || 0}&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_TICKETSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_TICKETSLIST', res.data);
        });
      }
    },
    setTicketsList: (context, payload) => {
      context.commit('GET_TICKETSLIST', payload);
    },
    getTicket(context) {
      return axios.get(api + router.app._route.params.ticketId).then((res) => {
        context.commit('GET_TICKET', res.data);
      });
    },
    setTicket: (context, payload) => {
      context.commit('GET_TICKET', payload);
    },
    getTicketAccount(context) {
      return axios.get(api + router.app._route.params.ticketId + '/account').then((res) => {
        context.commit('GET_TICKETACCOUNT', res.data);
      });
    },
    setTicketAccount: (context, payload) => {
      context.commit('GET_TICKETACCOUNT', payload);
    },
    getTicketAll(context, payload) {
      return axios.get(`${api}all?status=${payload || 'all'}`).then((res) => {
        context.commit('GET_TICKETALL', res.data);
      });
    },
    getTicketAllTwo(context, payload) {
      return axios.get(`${api}all?status=${payload || 'all'}`).then((res) => {
        context.commit('GET_TICKETALLTWO', res.data);
      });
    },
    setTicketAll: (context, payload) => {
      context.commit('GET_TICKETALL', payload);
      context.commit('GET_TICKETALLTWO', payload);
    },
    assignTicket: (context, { id, data }) => {
      return axios.patch(api + id, data);
    },
    actionTicket(context, payload) {
      return axios.post(api + router.app._route.params.ticketId + '/action', payload).then((res) => {
        context.commit('GET_TICKET', res.data);
        context.dispatch('getTicketMsg');
      });
    },
    getTicketMsg(context) {
      return axios.get(api + router.app._route.params.ticketId + '/messages/').then((res) => {
        context.commit('GET_TICKETMSG', res.data);
      });
    },
    setTicketMsg: (context, payload) => {
      context.commit('GET_TICKETMSG', payload);
    },
    sendTicketMsg(context, payload) {
      return axios({
        method: 'post',
        url: api + router.app._route.params.ticketId + '/messages/',
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        context.dispatch('getTicketMsg');
      });
    },
    webSocketTicket(context) {
      socket = new WebSocket(`wss://${url}/admin/ws/v1/tickets/${router.app._route.params.ticketId}?access_token=${token}`);

      socket.onopen = (e) => {};

      socket.onclose = (e) => {};

      socket.onmessage = (e) => {
        if (JSON.parse(e.data).event == 'ticket_updated') {
          context.dispatch('getTicket');
        } else if (JSON.parse(e.data).event == 'ticket_message_updated') {
          context.dispatch('getTicketMsg');
        }
      };

      socket.onerror = (error) => {
        if (router.app._route.name == 'Ticket') {
          context.dispatch('checkTicket');
        }
      };
    },
    checkTicket(context) {
      if (!socket || socket.readyState == 3) {
        context.dispatch('webSocketTicket');
      }
    },
    timerTicket(context) {
      timerID = setInterval(() => {
        context.dispatch('checkTicket');
      }, 5000);
    },
    closeWebSocketTicket() {
      clearInterval(timerID);
      if (socket) {
        socket.close(1000);
      }
      socket = null;
    },
  },
  mutations: {
    GET_TICKETSLIST: (state, payload) => {
      state.ticketsList = payload;
      return state.ticketsList;
    },
    GET_TICKET: (state, payload) => {
      state.ticket = payload;
      return state.ticket;
    },
    GET_TICKETACCOUNT: (state, payload) => {
      state.ticketAccount = payload;
      return state.ticketAccount;
    },
    GET_TICKETALL: (state, payload) => {
      state.ticketAll = payload;
      return state.ticketAll;
    },
    GET_TICKETALLTWO: (state, payload) => {
      state.ticketAllTwo = payload;
      return state.ticketAllTwo;
    },
    GET_TICKETMSG: (state, payload) => {
      state.ticketMsg = payload;
      return state.ticketMsg;
    },
  },
  getters: {
    ticketsList(state) {
      return state.ticketsList;
    },
    ticket(state) {
      return state.ticket;
    },
    ticketAccount(state) {
      return state.ticketAccount;
    },
    ticketAll(state) {
      return state.ticketAll;
    },
    ticketAllTwo(state) {
      return state.ticketAllTwo;
    },
    ticketMsg(state) {
      return state.ticketMsg;
    },
  },
};
