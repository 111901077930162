import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/notifications/';

export default {
  state: {
    notificationsList: { result: [] },
    notificationsAll: [],
    notificationsCount: 0,
  },
  actions: {
    getNotificationsList(context, { pageSize, page, read }) {
      if (read != 'all' || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${read != 'all' ? `?unread_only=${read}&` : '?'}page=${page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${read != 'all' ? `?unread_only=${read}&` : '?'}page=${page || 1}&page_size=${pageSize}`);
        }
        return axios
          .get(`${api}${read != 'all' ? `?unread_only=${read}&` : '?'}page=${page - 1 || 0}&page_size=${pageSize}`)
          .then((res) => {
            context.commit('GET_NOTIFICATIONSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_NOTIFICATIONSLIST', res.data);
        });
      }
    },
    setNotificationsList: (context, payload) => {
      context.commit('GET_NOTIFICATIONSLIST', payload);
    },
    getNotificationsAll(context) {
      return axios.get(api + 'all?unread_only=true').then((res) => {
        context.commit('GET_NOTIFICATIONSALL', res.data);
        context.dispatch('getNotificationsCount');
      });
    },
    setNotificationsAll: (context, payload) => {
      context.commit('GET_NOTIFICATIONSALL', payload);
    },
    getNotificationsCount(context) {
      return axios.get(api + 'unread-count').then((res) => {
        context.commit('GET_NOTIFICATIONSCOUNT', res.data);
      });
    },
    notificationsRead(context, payload) {
      return axios.post(api + payload + '/read').then(() => {
        context.dispatch('setNotificationsAll');
      });
    },
    notificationsReadAll(context) {
      return axios.post(api + 'read-all').then(() => {
        context.dispatch('setNotificationsAll');
      });
    },
  },
  mutations: {
    GET_NOTIFICATIONSLIST: (state, payload) => {
      state.notificationsList = payload;
      return state.notificationsList;
    },
    GET_NOTIFICATIONSALL: (state, payload) => {
      state.notificationsAll = payload;
      return state.notificationsAll;
    },
    GET_NOTIFICATIONSCOUNT: (state, payload) => {
      state.notificationsCount = payload;
      return state.notificationsCount;
    },
  },
  getters: {
    notificationsList(state) {
      return state.notificationsList;
    },
    notificationsAll(state) {
      return state.notificationsAll;
    },
    notificationsCount(state) {
      return state.notificationsCount;
    },
  },
};
