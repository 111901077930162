import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/prices/';

export default {
  state: {
    pricesList: {},
    prices: {},
  },
  actions: {
    getPricesList(context, { pageSize, search, page, account }) {
      if (!!search || page > 1 || pageSize > 10 || account != 'general') {
        if (
          `${router.app._route.path}${account != 'general' ? `?account=${account}&` : '?'}${search ? `src=${search}&` : ''}page=${
            page || 1
          }&page_size=${pageSize}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${account != 'general' ? `?account=${account}&` : '?'}${search ? `src=${search}&` : ''}page=${
              page || 1
            }&page_size=${pageSize}`
          );
        }
        return axios
          .get(
            `${api}${account != 'general' ? `?account=${account}&` : '?'}${search ? `src=${search}&` : ''}page=${
              page - 1 || 0
            }&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_PRICESLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_PRICESLIST', res.data);
        });
      }
    },
    setPricesList: (context, payload) => {
      context.commit('GET_PRICESLIST', payload);
    },
    createPrices(context, payload) {
      return axios.post(api, payload);
    },
    getPrices(context) {
      return axios.get(api + router.app._route.params.staffId).then((res) => {
        context.commit('GET_PRICES', res.data);
      });
    },
    setPrices: (context, payload) => {
      context.commit('GET_PRICES', payload);
    },
    updatePrices(context, { id, data }) {
      return axios.patch(api + id, data);
    },
    deletePrices(context, payload) {
      return axios.delete(api + payload);
    },
  },
  mutations: {
    GET_PRICESLIST: (state, payload) => {
      state.pricesList = payload;
      return state.pricesList;
    },
    GET_PRICES: (state, payload) => {
      state.prices = payload;
      return state.prices;
    },
  },
  getters: {
    pricesList(state) {
      return state.pricesList;
    },
    prices(state) {
      return state.prices;
    },
  },
};
