<template>
  <v-navigation-drawer height="calc(100vh - 64px)" color="secondary" expand-on-hover permanent clipped class="drawer-top" app>
    <MenuList />
  </v-navigation-drawer>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
};
</script>

<style lang="scss" scoped>
.drawer-top {
  margin-top: 64px;
  scrollbar-color: #595959 #f7f7fa;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 6px;
    background-color: var(--v-accent-base);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: var(--v-opasity-base);
  }
  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: var(--v-accent-base);
  }
}
</style>
