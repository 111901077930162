import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/statements/';

export default {
  state: {
    statementsList: { result: [] },
  },
  actions: {
    getStatementsList(context, { pageSize, account, date, page, dateFrom, dateTo }) {
      if (date != 'this_year' || account != 'all' || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}?page=${page}&page_size=${pageSize}${account != 'all' ? `&account=${account}` : ''}&period=${date}${
            date == 'custom' ? `&period_from=${dateFrom}` : ''
          }${date == 'custom' ? `&period_to=${dateTo}` : ''}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}?page=${page}&page_size=${pageSize}${account != 'all' ? `&account=${account}` : ''}&period=${date}${
              date == 'custom' ? `&period_from=${dateFrom}` : ''
            }${date == 'custom' ? `&period_to=${dateTo}` : ''}`
          );
        }
        return axios
          .get(
            `${api}?page=${page - 1 || 0}&page_size=${pageSize}${account != 'all' ? `&account=${account}` : ''}&period=${date}${
              date == 'custom' ? `&period_from=${Date.parse(dateFrom)}` : ''
            }${date == 'custom' ? `&period_to=${Date.parse(dateTo)}` : ''}`
          )
          .then((res) => {
            context.commit('GET_STATEMENTSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}&page_size=${pageSize}&period=${date}`).then((res) => {
          context.commit('GET_STATEMENTSLIST', res.data);
        });
      }
    },
    setStatementsList: (context, payload) => {
      context.commit('GET_STATEMENTSLIST', payload);
    },
  },
  mutations: {
    GET_STATEMENTSLIST: (state, payload) => {
      state.statementsList = payload;
      return state.statementsList;
    },
  },
  getters: {
    statementsList(state) {
      return state.statementsList;
    },
  },
};
