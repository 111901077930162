import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/refbook/';

export default {
  state: {
    refBookList: {},
  },
  actions: {
    getRefList(context, { search, page, pageSize }) {
      if (!!search || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}`);
        }
        return axios.get(`${api}${search ? `?src=${search}&` : '?'}page=${page - 1 || 0}&page_size=${pageSize}`).then((res) => {
          context.commit('GET_REFBOOKLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}&page_size=${pageSize}`).then((res) => {
          context.commit('GET_REFBOOKLIST', res.data);
        });
      }
    },
    setRefList: (context, payload) => {
      context.commit('GET_REFBOOKLIST', payload);
    },
    createRefBook(context, payload) {
      return axios.post(api, payload);
    },
    updateRefBook(context, { id, data }) {
      return axios.put(`${api}${id}`, data);
    },
    deleteRefBook(context, payload) {
      return axios.delete(`${api}${payload}`);
    },
  },
  mutations: {
    GET_REFBOOKLIST: (state, payload) => {
      state.refBookList = payload;
      return state.refBookList;
    },
  },
  getters: {
    refBookList(state) {
      return state.refBookList;
    },
  },
};
