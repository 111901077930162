import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: true,
      light: {
        primary: '#00C365',
        secondary: '#18212A',
        warning: '#ffc936',
        accent: '#F8F8F8',
        error: '#FF4F4F',
        success: '#00CD36',
        input: '#0F5DBB',
        opasity: '#8B8B91',
        gray: '#D0D0D0',
      },
    },
  },
});
