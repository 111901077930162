<template>
  <div class="empty-header">
    <v-app-bar height="64" color="white" fixed padless>
      <div>
        <img height="42" src="@/assets/img/logoB.svg" alt="logo" />
      </div>
      <v-spacer></v-spacer>
      <div v-if="this.$route.name != 'Login'" class="header-select mt-4">
        <v-select v-model="select" :items="lang" item-text="name" item-value="key" value="ENG" dense></v-select>
      </div>
    </v-app-bar>
    <notifications></notifications>
    <div class="empty-layout">
      <transition name="fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select: { name: 'ENG', key: 'en' },
      lang: [
        { name: 'РУС', key: 'ru' },
        { name: 'УКР', key: 'ua' },
        { name: 'ENG', key: 'en' },
      ],
    };
  },
};
</script>

<style>
.fade-leave-to,
.fade-enter {
  opacity: 0;
  transform: scale(0) translateX(0);
}
.fade-enter-active {
  transition: all 0.7s ease;
}
.fade-enter-to {
  opacity: 1;
}
.fade-leave-active {
  transition: all 0s ease;
}
.fade-leave {
  opacity: 1;
  transform: scale(1) translateX(0);
}
.empty-layout {
  width: 100%;
  min-height: calc(100vh - 64px);
  margin-top: 64px;
  display: flex;
  align-items: center;
}
.header-select {
  max-width: 80px;
  z-index: 18;
}
.empty-header .button {
  width: 184px;
}
@media screen and (max-width: 599px) {
  .empty-header .button {
    width: 120px;
  }
}
</style>
