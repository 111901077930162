import '@/plugins/axios';
import router from '@/router/index';

const api = '/admin/api/v1/';

const url = window.location.host;
let socket = null;
let timerID = null;

export default {
  state: {
    countries: [],
    currency: [],
    staffRoleList: [],
    memberRoleList: [],
    pagination: [],
    ticketStatus: [],
    ticketTopic: [],
    accountStatus: [],
    billengPeriod: [],
    paymentModes: [],
    periods: [{}],
    msgStatus: [],
  },
  actions: {
    getCoutries(context) {
      return axios.get(api + 'countries/').then((res) => {
        context.commit('GET_COUNTRIES', res.data);
      });
    },
    setCoutries: (context, payload) => {
      context.commit('GET_COUNTRIES', payload);
    },
    getCurrency(context) {
      return axios.get(api + 'currencies/').then((res) => {
        context.commit('GET_CURRENCY', res.data);
      });
    },
    getPagination(context) {
      return axios.get(api + 'pagination-options/').then((res) => {
        context.commit('GET_PAGINATION', res.data);
      });
    },
    getStaffRoles(context) {
      return axios.get(api + 'staff-roles/').then((res) => {
        context.commit('GET_STAFFROLELIST', res.data);
      });
    },
    getMemberRoles(context) {
      return axios.get(api + 'account-member-roles/').then((res) => {
        context.commit('GET_MEMBERROLELIST', res.data);
      });
    },
    getTicketStatus(context) {
      return axios.get(api + 'tickets-statuses/').then((res) => {
        context.commit('GET_TICKETSTATUS', res.data);
      });
    },
    getTicketTopic(context) {
      return axios.get(api + 'tickets-topics/').then((res) => {
        context.commit('GET_TICKETTOPIC', res.data);
      });
    },
    getAccountStatus(context) {
      return axios.get(api + 'account-statuses/').then((res) => {
        context.commit('GET_ACCOUNTSTATUS', res.data);
      });
    },
    getBillengPeriod(context) {
      return axios.get(api + 'billing-periods/').then((res) => {
        context.commit('GET_BILLINGPERIOD', res.data);
      });
    },
    getPeymentModes(context) {
      return axios.get(api + 'payment-modes/').then((res) => {
        context.commit('GET_PAYMENTMODES', res.data);
      });
    },
    getPeriods(context) {
      return axios.get(api + 'periods/').then((res) => {
        context.commit('GET_PERIODS', res.data);
      });
    },
    getMsgStatus(context) {
      return axios.get(api + 'message-statuses/').then((res) => {
        context.commit('GET_MSGSTATUS', res.data);
      });
    },
    webSocket(context) {
      socket = new WebSocket(`wss://${url}/admin/ws/v1/?access_token=${sessionStorage.getItem('jwt_token')}`);

      socket.onopen = (e) => {};

      socket.onclose = () => {
        if (router.app._route.name != 'Login') {
          context.dispatch('check');
        }
      };

      socket.onmessage = (e) => {
        if (JSON.parse(e.data).event == 'notification') {
          context.dispatch('getNotificationsAll');
        } else if (JSON.parse(e.data).event == 'unread_notification_count') {
          context.dispatch('getNotificationsCount');
        }
      };

      socket.onerror = () => {
        if (router.app._route.name != 'Login') {
          context.dispatch('check');
        }
      };
    },
    check(context) {
      if (!socket || socket.readyState == 3) {
        context.dispatch('webSocket');
      }
    },
    timer(context) {
      timerID = setInterval(() => {
        context.dispatch('check');
      }, 5000);
    },
    closeWebSocket() {
      clearInterval(timerID);
      if (socket) {
        socket.close(1000);
      }
      socket = null;
    },
  },
  mutations: {
    GET_COUNTRIES: (state, payload) => {
      state.countries = payload;
      return state.countries;
    },
    GET_CURRENCY: (state, payload) => {
      state.currency = payload;
      return state.currency;
    },
    GET_PAGINATION: (state, payload) => {
      state.pagination = payload;
      return state.pagination;
    },
    GET_STAFFROLELIST: (state, payload) => {
      state.staffRoleList = payload;
      return state.staffRoleList;
    },
    GET_MEMBERROLELIST: (state, payload) => {
      state.memberRoleList = payload;
      return state.memberRoleList;
    },
    GET_TICKETSTATUS: (state, payload) => {
      state.ticketStatus = payload;
      return state.ticketStatus;
    },
    GET_TICKETTOPIC: (state, payload) => {
      state.ticketTopic = payload;
      return state.ticketTopic;
    },
    GET_ACCOUNTSTATUS: (state, payload) => {
      state.accountStatus = payload;
      return state.accountStatus;
    },
    GET_BILLINGPERIOD: (state, payload) => {
      state.billengPeriod = payload;
      return state.billengPeriod;
    },
    GET_PAYMENTMODES: (state, payload) => {
      state.paymentModes = payload;
      return state.paymentModes;
    },
    GET_PERIODS: (state, payload) => {
      state.periods = payload;
      return state.periods;
    },
    GET_MSGSTATUS: (state, payload) => {
      state.msgStatus = payload;
      return state.msgStatus;
    },
  },
  getters: {
    countries(state) {
      return state.countries;
    },
    currency(state) {
      return state.currency;
    },
    pagination(state) {
      return state.pagination;
    },
    staffRoleList(state) {
      return state.staffRoleList;
    },
    memberRoleList(state) {
      return state.memberRoleList;
    },
    ticketStatus(state) {
      return state.ticketStatus;
    },
    ticketTopic(state) {
      return state.ticketTopic;
    },
    accountStatus(state) {
      return state.accountStatus;
    },
    billengPeriod(state) {
      return state.billengPeriod;
    },
    paymentModes(state) {
      return state.paymentModes;
    },
    periods(state) {
      return state.periods;
    },
    msgStatus(state) {
      return state.msgStatus;
    },
  },
};
